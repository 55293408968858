// src/components/Home.js

import React, { useEffect, useState, useRef } from 'react';
import './Home.css';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { MapContainer, TileLayer, GeoJSON, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import countriesGeoJSON from '../../assets/images/custom.geo.json';
import { FaGlobe, FaFlask, FaBox, FaChartPie } from 'react-icons/fa';
import L from 'leaflet';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getAllCountryCentroids } from '../../utils/geoUtils';

/* Static Data Arrays */
const categoryImages = [
  { id: 1, image: '/images/mutfak.mp4', name: 'Mutfak Hİjyenİ', category: 'mutfak', description: "KRL Kimya ürünleri ile mutfaklarınızda %100 hijyen." },
  { id: 2, image: '/images/bina_bakimi.mp4', name: 'Bİna Bakımı', category: 'bina-bakimi', description: "Konsantre ve çevreye duyarlı ürünlerle temiz çalışma ortamı." },
  { id: 3, image: '/images/kisisel.mp4', name: 'Kİşİsel Hİjyen', category: 'kisisel-hijyen', description: "Kendinize değer katın." },
  { id: 4, image: '/images/camasir.mp4', name: 'Çamaşır Hİjyenİ', category: 'camasir', description: "Kendinize değer katın." },
  { id: 5, image: '/images/gida_hijyen.mp4', name: 'Gıda Hİjyenİ', category: 'gida-hijyeni', description: "Parfümlü yumuşatıcılar ile beraber ağır kir ve yağ çözücü ürünler." },
  { id: 6, image: '/images/pool.mp4', name: 'Havuz Kİmyasalları', category: 'havuz-kimyasallari', description: "Otel, villa, site vb. yerlerde havuz hijyeninizi sağlayalım." },
  { id: 7, image: '/images/ozel_urunler.mp4', name: 'Özel ÜrÜnler', category: 'ozel-urunler', description: "Ürünlerimizin pratik ve kolay kullanımı sayesinde hızlı ve parlak temizlik." },
  { id: 8, image: '/images/endustriyel.mp4', name: 'EndÜstrİyel Kİmyasallar', category: 'endustriyel-kimya', description: "Ürünlerimizin pratik ve kolay kullanımı sayesinde hızlı ve parlak temizlik." },
];

const brands = [
  {
    id: 1,
    features: [
      'Sektör ihtiyaçlarına odaklı',
      'Konsantre ve ekonomik',
      'Çevreye duyarlı',
      'Pratik ve kolay kullanım',
    ],
    image: '/images/equinox_logo.png'
  },
  {
    id: 2,
    features: [
      'Gıda üretim tesislerinde hijyen',
      'Seyreltme sistemlerine uygun',
      'Yüksek konsantrasyon',
    ],
    image: '/images/endaxi_logo.png'
  },
  {
    id: 3,
    features: [
      'Sıvı ve toz endüstriyel çamaşır yıkama ürünleri',
      'Düşük su sıcaklıklarında etkin çözümler',
      'Yüksek konsantrasyon',
    ],
    image: '/images/lora_logo.png'
  },
  {
    id: 4,
    features: [
      'Sanayi üretim tesislerinde endüstriyel çözümler',
      'Bakım ve koruma kimyasalları',
      'Pratik ve kolay kullanım, hızlı çözümler',
    ],
    image: '/images/chemiccount_logo.png'
  }
];

const certificates = [
  {
    logo: '/images/certificate-logo1.png',
    pdf: 'https://fra1.digitaloceanspaces.com/krl-kimya/certificates/certificate1.pdf',
  },
  {
    logo: '/images/certificate-logo2.png',
    pdf: 'https://fra1.digitaloceanspaces.com/krl-kimya/certificates/certificate2.pdf',
  },
  {
    logo: '/images/certificate-logo3.png',
    pdf: 'https://fra1.digitaloceanspaces.com/krl-kimya/certificates/certificate3.pdf',
  },
  {
    logo: '/images/certificate-logo5.png',
    pdf: 'https://fra1.digitaloceanspaces.com/krl-kimya/certificates/certificate4.pdf',
  },
];

// References Array (47 logos)
const references = Array.from({ length: 47 }, (_, index) => ({
  id: index + 1,
  logo: `https://krl-kimya.fra1.digitaloceanspaces.com/references/reference${index + 1}.png`,
  // You can replace 'Company Name' with actual company names if available
  name: `Company Name ${index + 1}`,
}));

const statsData = [
  {
    id: 1,
    icon: <FaGlobe />,
    targetNumber: 500,
    label: 'YEREL VE ULUSLARARASI MÜŞTERİ',
    backgroundColor: '#3498db',
    suffix: '+',
    start: 0,
  },
  {
    id: 2,
    icon: <FaFlask />,
    targetNumber: 60000,
    label: 'LİTRE ÜRETİM KAPASİTESİ',
    backgroundColor: '#2471a3',
    suffix: '+',
    start: 10000, // Start from 20,000 for faster counting
  },
  {
    id: 3,
    icon: <FaBox />,
    targetNumber: 500,
    label: 'ÜRÜN KALEMİ',
    backgroundColor: '#2471a3',
    suffix: '+',
    start: 0,
  },
  {
    id: 4,
    icon: <FaChartPie />,
    targetNumber: 40,
    label: 'İHRACAT ORANI',
    backgroundColor: '#1f618d',
    suffix: '%',
    start: 0,
  },
];

/* Initialize Animated Marker Icon */
const AnimatedMarkerIcon = L.divIcon({
  html: `<div class="custom-marker"><i class="fas fa-map-marker-alt"></i></div>`,
  className: 'animated-marker',
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  popupAnchor: [0, -30],
});


const Home = () => {
  const navigate = useNavigate();

  /* State Management */
  const [showBrands, setShowBrands] = useState(false);
  const [showCertificates, setShowCertificates] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [counts, setCounts] = useState(statsData.map(stat => stat.start));
  const [selectedReference, setSelectedReference] = useState(null);
  const [showAllReferences, setShowAllReferences] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const slideInterval = useRef(null); // Reference to the interval

  /* References for intervals */
  const autoSlideInterval = useRef(null); // For auto-sliding
  const statsInterval = useRef(null);     // For stats counting

  const categoryCount = categoryImages.length;

  /* Function to go to the next slide */
  const goToNextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % categoryCount);
  };

  /* Function to go to the previous slide */
  const goToPrevSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + categoryCount) % categoryCount);
  };

  /* Function to reset and start the auto-slide interval */
  const resetAutoSlide = () => {
    if (autoSlideInterval.current) {
      clearInterval(autoSlideInterval.current);
    }
    autoSlideInterval.current = setInterval(goToNextSlide, 5000); // 5 seconds
  };


  /* Navigation Functions */
  const navigateToCategory = (category) => {
    navigate(`/products?category=${category}`);
  };

  const navigateToContact = () => {
    navigate(`/contact`);
  };

  const navigateToCatalog = () => {
    navigate(`/catalog`);
    window.scrollTo(0, 0); // Scroll to the top of the page immediately
  };

  const navigateToDocuments = () => {
    navigate(`/documents`);
    window.scrollTo(0, 0); // Scroll to the top of the page immediately
  };




  /* Initialize AOS and Auto-Slide on component mount */
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false, // Allow animations to trigger multiple times
    });

    // Show Brands after slider animation (500ms)
    const brandsTimer = setTimeout(() => {
      setShowBrands(true);
      AOS.refresh(); // Refresh AOS to detect new elements
    }, 500);

    // Show Certificates after Brands animation (1000ms)
    const certificatesTimer = setTimeout(() => {
      setShowCertificates(true);
      AOS.refresh();
    }, 1000);

    // Start Auto-Slide
    resetAutoSlide();

    // Cleanup on unmount
    return () => {
      clearTimeout(brandsTimer);
      clearTimeout(certificatesTimer);
      if (slideInterval.current) {
        clearInterval(slideInterval.current);
      }
      if (autoSlideInterval.current) {
        clearInterval(autoSlideInterval.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Reset the auto-slide interval when user manually navigates */
  const handlePrevClick = () => {
    goToPrevSlide();
    resetAutoSlide();
  };

  const handleNextClick = () => {
    goToNextSlide();
    resetAutoSlide();
  };

  /* Stats Counting Effect */
  useEffect(() => {
    const interval = setInterval(() => {
      setCounts(prevCounts =>
        prevCounts.map((count, index) => {
          const { targetNumber } = statsData[index];

          // Increase count faster for large numbers
          const increment = targetNumber > 10000 ? 100 : 1;

          if (count < targetNumber) {
            return Math.min(count + increment, targetNumber);
          } else {
            return count;
          }
        })
      );
    }, 20); // Update every 20ms for a smooth count

    slideInterval.current = interval; // Reuse the interval reference for counting

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const allReached = counts.every(
      (count, index) => count >= statsData[index].targetNumber
    );
    if (allReached && slideInterval.current) {
      clearInterval(slideInterval.current);
    }
  }, [counts]);

  /* Handle image load errors by setting a fallback image */
  const handleImageError = (e) => {
    e.target.src = '/references/fallback.png'; // Ensure you have a fallback image at this path
  };

  /* Handle window resize for isMobile state */
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  /* Styling for the GeoJSON map */
  const countryStyle = {
    fillColor: "#038aff",
    weight: 1,
    opacity: 1,
    color: "white",
    fillOpacity: 0.4
  };

  /* Get all country centroids */
  const countryCentroids = getAllCountryCentroids(countriesGeoJSON);

  return (
    <div className="home-container">
      {/* Slider Section */}
      <div className="slider">
        <div
          className={`slider-container ${showBrands ? 'accordion-animation' : ''}`}
          onTouchStart={(e) => e.stopPropagation()}
          onTouchMove={(e) => e.stopPropagation()}
          onTouchEnd={(e) => e.stopPropagation()}
        >
          <div className="slider">
            {categoryImages.map((item, index) => (
              <div key={item.id} className={`slide ${index === activeIndex ? 'active' : ''}`}>
                <video
                  autoPlay
                  loop
                  muted
                  playsInline // Prevents fullscreen on mobile
                  disablePictureInPicture // Disables PiP mode
                  className="video-slide"
                >
                  <source src={item.image} type="video/mp4" />
                </video>
                <div className="slider-content">
                  <div className="slider-info">
                    <h1>{item.name}</h1>
                    <p>{item.description}</p>
                  </div>
                  <div className="slider-actions">
                    <button className="btn" onClick={() => navigateToCategory(item.category)}>ÜRÜNLERİ İNCELE</button>
                    <button className="btn demo" onClick={navigateToContact}>DEMO TALEP ET</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* Update button onClick handlers */}
          <button className="control prev" onClick={handlePrevClick} aria-label="Previous Slide">
            &#10094;
          </button>
          <button className="control next" onClick={handleNextClick} aria-label="Next Slide">
            &#10095;
          </button>
        </div>
      </div>

      {/* Brands Section - Render right after Slider */}
      {showBrands && (
        <div className="brands-info-container" data-aos="fade-up">
          {brands.map((brand, index) => (
            <div key={brand.id} className="brands-card" data-aos="zoom-in" data-aos-delay={index * 200}>
              <div className="brands-image-container">
                <img
                  src={brand.image}
                  alt={`Brand Image ${index + 1}`}
                  className="brands-image"
                  loading="lazy"
                />
              </div>
              <div className="brands-content">
                <ul>
                  {brand.features.map((feature, idx) => (
                    <li key={idx}>{feature}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Certificates Section */}
      {showCertificates && (
        <section className="certificate-section" data-aos="fade-up">
          {certificates.map((certificate, index) => (
            <div
              key={index}
              className="certificate-logo-container"
              data-aos="fade-up"
              data-aos-delay={index * 200} // Staggered delay
            >
              <a href={certificate.pdf} target="_blank" rel="noopener noreferrer">
                <img
                  src={certificate.logo}
                  alt={`Sertifika ${index + 1}`}
                  className="certificate-logo img-fluid"
                  loading="lazy"
                  onError={(e) => { e.target.src = '/images/fallback-logo.png'; }}
                />
              </a>
            </div>
          ))}
        </section>
      )}

      {/* Kataloglarımız Button Section */}
      <div className="catalog-container" data-aos="zoom-in" data-aos-duration="800">
        <p>Ürün kataloglarımızı  ve belgelerimizi incelemek için aşağıdaki butonlara tıklayınız.</p>
        <button className="catalog-button" onClick={navigateToCatalog}>
          Kataloglarımız
        </button>
        <button className="catalog-button" onClick={navigateToDocuments}>
          Belgelerİmİz
        </button>
      </div>

      {/* Conditionally Render Map Section */}
      {!isMobile && (
        <section className="map-section" data-aos="fade-up" data-aos-delay="1500">
          <h2 className="map-title">Global Hizmetlerimiz</h2>
          <MapContainer
            center={[45, 30]}
            zoom={5}
            style={{ height: '500px', width: '100%' }}
            scrollWheelZoom={false}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <GeoJSON data={countriesGeoJSON} style={countryStyle} />

            {/* Add Markers with Animated Icons */}
            {countryCentroids.map((country, index) => (
              <Marker key={index} position={country.coordinates} icon={AnimatedMarkerIcon} opacity={0.8}>
                <Popup>{country.name}</Popup>
              </Marker>
            ))}
          </MapContainer>
        </section>
      )}

      {/* Stats Section */}
      <div className="stats-container" data-aos="fade-up">
        {statsData.map((stat, index) => (
          <div
            key={stat.id}
            className="stat"
            style={{ backgroundColor: stat.backgroundColor }}
          >
            <div className="stat-icon">{stat.icon}</div>
            <div className="stat-number">
              {counts[index]}
              {stat.suffix}
            </div>
            <div className="stat-label">{stat.label}</div>
          </div>
        ))}
      </div>

      {/* References Section */}
      <section className="references-container">
        <h2 className="references-header">Referanslarımız</h2>

        {/* References Grid */}
        <div className="references-grid" data-aos="fade-up">
          {(isMobile && !showAllReferences ? references.slice(0, 6) : references).map((reference) => (
            <div
              key={reference.id}
              className="reference-card"
              onClick={() => setSelectedReference(reference)}
              tabIndex="0"
              role="button"
              onKeyPress={(e) => {
                if (e.key === 'Enter') setSelectedReference(reference);
              }}
              data-aos="zoom-in"
              data-aos-delay={reference.id * 50} // Staggered delay
            >
              <img
                src={reference.logo}
                alt={reference.name}
                className="reference-logo"
                loading="lazy"
                onError={handleImageError}
              />
            </div>
          ))}
        </div>

        {/* Button to Show All References on Mobile */}
        {isMobile && !showAllReferences && (
          <button className="show-all-references-btn" onClick={() => setShowAllReferences(true)}>
            Tüm referansları görmek için tıklayınız.
          </button>
        )}

        {/* Modal for Reference Details */}
        {selectedReference && (
          <div className="modal-overlay" onClick={() => setSelectedReference(null)}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <button className="modal-close" onClick={() => setSelectedReference(null)} aria-label="Close Modal">
                &times;
              </button>
              <img
                src={selectedReference.logo}
                alt={selectedReference.name}
                className="modal-logo"
                loading="lazy"
                onError={handleImageError}
              />
              {/* You can add more details about the reference here if available */}
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default Home;
